import React, { useEffect, useState } from "react";
import { isNil, isEmpty, countBy, reject, includes } from "ramda";
import { JsonRpcProvider } from "@ethersproject/providers";

import "@fontsource/work-sans/500.css";

import ENSLogo from "./images/ENS-logo.svg";
import ENSIcon from "./images/icon.svg";
import OVRLogo from "./images/logo-over.png";

const endpointUrl = "https://mws.ovr.ai/services/ens/info";

const loadConfig = async () => {
  try {
    const response = await fetch(endpointUrl, { method: "GET" });
    const res = await response.json();
    const {
      result = false,
      data: { defaultSite = null, provider = null },
    } = res;

    if (result) {
      return { defaultSite, provider };
    }

    return null;
  } catch (err) {
    console.error("Error", err);
    return null;
  }
};

const guessSubdomain = (slices) => {
  const final = reject((single) =>
    includes(single, ["https", "http", "localhost", "ovr", "eth", "link"])
  )(slices);
  if (isEmpty(final)) return null;
  return final[0];
};

const App = () => {
  const [loading, setLoading] = useState(true);
  const [nftId, setNftId] = useState("");
  const [defaultSite, setDefaultSite] = useState("");
  const [subdomain, setSubdomain] = useState("");

  async function ensRedirect() {
    const config = await loadConfig();
    if (config == null) {
      return;
    }

    const { defaultSite, provider: givenProvider } = config;
    console.debug("Config", defaultSite);
    setDefaultSite(defaultSite);
    const provider = new JsonRpcProvider(givenProvider);

    const url = window.location.hostname;

    const isLink = url.includes(".link");

    const slices = url.split(/[.//_:/]+/);
    const { true: ovrCounts } = countBy((c) => c === "ovr")(slices);
    let subdomain = guessSubdomain(slices);

    console.debug("Data", { isLink });
    console.debug("Subdomain", { subdomain, slices });

    try {
      if (!isNil(subdomain)) {
        setSubdomain(subdomain);
      } else {
        if (ovrCounts > 1) {
          subdomain = "ovr";
          setSubdomain("ovr");
        } else {
          subdomain = null;
        }
      }

      if (isNil(subdomain)) {
        setLoading(false);
        setSubdomain("");

        return;
      } else {
        const resolver = await provider.getResolver(`${subdomain}.ovr.eth`);
        const nftId = await resolver.getText("notice");
        console.debug("OVRLand nftId:", nftId);

        if (isEmpty(nftId) || isNil(nftId)) {
          setLoading(false);
        } else {
          setNftId(nftId);
          setLoading(false);
        }
      }
    } catch (err) {
      setLoading(false);
      setSubdomain("");
      console.error("Error", err);
    }

    setLoading(false);
  }

  const handleClick = () => {
    window.location.href = `${defaultSite}${nftId}`;
  };

  useEffect(() => {
    ensRedirect();
  }, []);
  return (
    <div className="App">
      <div className="container">
        <div className="container-logos">
          <img src={OVRLogo} className="ovr-logo" alt="OVER Logo" />
          <span className="divider">x</span>
          <img src={ENSLogo} className="ens-logo" alt="ENS Logo" />
        </div>

        <div
          className={`container-domain ${
            !isEmpty(subdomain) && !isEmpty(nftId) ? "visible" : ""
          }`}
          onClick={handleClick}
        >
          <div className="domain">
            <img src={ENSIcon} className="ens-logo-domain" alt="Icon" />
            <span>{subdomain}.ovr.eth</span>
          </div>
        </div>

        <div
          className={`error ${
            isEmpty(subdomain) && !loading ? "visible" : ""
          } `}
        >
          Error: Invalid or Missing subdomain or OVRLand not linked.
        </div>

        <div className={`container-loading ${!loading && "loaded"}`}>
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
